import React from 'react';
import DocumentMeta from 'react-document-meta';
import { meta } from './constants/meta';
import { useRouter } from './myHooks';

const Index = ({children}) => {
    let { pathname } = useRouter();

    return (
        <div>
            <DocumentMeta {...meta[pathname || '/']} /> 
            {children}
        </div>
    )
}

export default Index;
