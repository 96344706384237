exports.meta = {
  '/': {
    title: 'פרחי ליאור בנשר בנשר | אומנות בפרחים',
    description:
      'פרחי ליאור בנשר בנשר היא חנות בעלת ותק של מעל 20 שנה ידועה ביכולות העיצובים והאומנות המיוחדת שלה בפרחי ליאור בנשר מתמחים בעיצוב זרי פרחים בכל הגדלים האפשריים סידורי פרחים מעוצבים לכל אירוע בהתאמה אישית כמו כן ניתן למצוא מבחר עשיר של עציצים עצי נוי במבוקים סחלבים זרי כלה קישוטי רכב זרי פרחים לראש מבחר בלוני הליום הגדול בישראל מארזי שוקולד משקאות דובונים ומוצרי פרוה קופסאות פרחים מסכים ואזות זכוכית כלי קרמיקה פרי משי נרות מעוצבים מספרים אותיות ועוד מגשי פירות מעוצבים כרטיסי ברכה ספלים מעוצבים ועוד... עיצוב פרחים בלונים ופירות לאירועים משלוחים בפריסה ארצית.',
    canonical: 'https://liorflowers.co.il/',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'פרחים, נשר, ליאור פרחים, יקנעם, משלחוים, ורדים'
      }
    }
  },
  '/pages/location': {
    title: 'פרחי ליאור בנשר | אומנות בפרחים',
    description:
      'יש לפנות לרחוב פרץ, בפניה הראשונה יש לפנות לרחוב דרך השלום, בהגיעכם לכיכר, צאו ביציאה הראשונה, כ200 מטר בצד ימין תגיעו לחנות פרחי ליאור בנשר.',
    canonical: 'https://liorflowers.co.il/pages/location',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'פרחים, נשר, ליאור פרחים, יקנעם, משלחוים, ורדים'
      }
    }
  },
  '/pages/about': {
    title: 'פרחי ליאור בנשר | אומנות בפרחים',
    description:
      'פרחי ליאור בנשר בנשר היא חנות בעלת ותק של מעל 20 שנה ידועה ביכולות העיצובים והאומנות המיוחדת שלה בפרחי ליאור בנשר מתמחים בעיצוב זרי פרחים בכל הגדלים האפשריים סידורי פרחים מעוצבים לכל אירוע בהתאמה אישית כמו כן ניתן למצוא מבחר עשיר של עציצים עצי נוי במבוקים סחלבים זרי כלה קישוטי רכב זרי פרחים לראש מבחר בלוני הליום הגדול בישראל מארזי שוקולד משקאות דובונים ומוצרי פרוה קופסאות פרחים מסכים ואזות זכוכית כלי קרמיקה פרי משי נרות מעוצבים מספרים אותיות ועוד מגשי פירות מעוצבים כרטיסי ברכה ספלים מעוצבים ועוד... עיצוב פרחים בלונים ופירות לאירועים משלוחים בפריסה ארצית.',
    canonical: 'https://liorflowers.co.il/pages/about',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'פרחים, נשר, ליאור פרחים, יקנעם, משלחוים, ורדים'
      }
    }
  },
  '/pages/contact': {
    title: 'פרחי ליאור בנשר | אומנות בפרחים',
    description:
      'שעות פתיחה: 04-820-2003  ראשון עד חמישי:  07:45 - 20:00 שישי וערבי חג: 06:00 - שעתיים לכניסת השבת/חג נשר, רח׳ ברק 2',
    canonical: 'https://liorflowers.co.il/pages/contact',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'פרחים, נשר, ליאור פרחים, יקנעם, משלחוים, ורדים'
      }
    }
  },
  '/elements/categories?category=kishut': {
    title: 'פרחי ליאור בנשר | אומנות בפרחים',
    description:
      'כבר מעל 20 שנה ועם קרוב ל3000 רכבים מקושטים, בפרחי ליאור בנשר אנו דואגים לכם לרכב כמו שרק לחתן וכלה מגיע, עם העיצובים הכי יפים והכי עכשויים, ביום הגדול שלכם בוחרים רק בפרחי ליאור בנשר, פנו אלינו',
    canonical: 'https://liorflowers.co.il/elements/categories?category=kishut',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'פרחים, נשר, ליאור פרחים, יקנעם, משלחוים, ורדים'
      }
    }
  },
  '/elements/categories?category=hopot': {
    title: 'פרחי ליאור בנשר | אומנות בפרחים',
    description:
    'כל חייכם חיכיתם לרגע הזה, מצאתם את בחיר ליבכם ואתם עתידים לעמוד איתו מתחת לחופה, פרפרים בחזה, הרגש מציף אתכם, כל העיניים נשואות אליכם, הצלם מיישר מבט והתמונות מתחילות, רעש הצמצם והבזק האור שקצת מסנוור, פרחי ליאור בנשר עם ניסיון של 20 שנה, מעצבים עבורכם את החופה הכי יפה ומיוחדת שיש, כזאת שאף אורח לא ישכח שראה אתכם עומדים בה, חופה כזאת שתשדרג את אלבום החתונה שלכם, להתאמת החופה הכי מתאימה עבורכם, פנו אלינו:',
    canonical: 'https://liorflowers.co.il/elements/categories?category=hopot',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'פרחים, נשר, ליאור פרחים, יקנעם, משלחוים, ורדים'
      }
    }
  },
};
