import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './routes';
import AppShell from './appShell';


const App = () => {
    return (
        <div>
            <BrowserRouter basename={ '/' }>
            <AppShell>
                
                    <AppRoute/>
                
            </AppShell>
            </BrowserRouter>
        </div>
    )
}

export default App
