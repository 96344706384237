const initialState = {
    filter: 'flowers'
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LAST':
        return {
          ...state,
          filter: action.value
        };
      default:
        return state;
    }
  };
  